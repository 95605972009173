import React from "react";
import { Link } from "react-router-dom";

function ProductIntro({ price, name, title, img1, img2 }) {
    return(
        <section className="pis">
            <div className="pisd-wrap">
                <div className="pipd w3-animate-left">
                    <img src={img1} className="img-fluid" alt=""/>
                    <img src={img2} className="img-fluid" alt=""/>
                </div>
                <div className="pipdet w3-animate-left">
                        <h5 style={{"fontWeight" : "bold" }}>{price}</h5>
                        <h3 style={{"fontWeight" : "bold" }}>{name}</h3>
                        <p style={{"color" : "black", "fontSize" : "15px" }}>
                            {title}
                        </p>
                    <div className="pipdetbtns">
                        <div>
                            <Link to="/Kapcsolatfelvetel"><button className="btn-filled2">Kapcsolatfelvétel</button></Link>
                        </div>
                        <div>
                            <Link><button className="btn-outline2">További termékek</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProductIntro;