import React, { useState } from "react";
import Comment from "./Comment";
import "../style/Comments.css";
import CommentToPost from "./CommentToPost";

function Comments({Comments, id}) {

    const [isReplyC, setIsReplyC] = useState("");
    const [idCommentB, setIdCommentB] = useState("")
    
    const getReply = (isReply, idComment) => {
        setIsReplyC(isReply);
        console.log(isReply, idComment)
    };

    return(
        <section>
            <h1 className="ch1">Hozzászólások</h1>
            {Comments.length > 0 ? Comments.map((commentpost) =>
                <Comment onSubmit={getReply} id={commentpost.id} comment={commentpost.comment} name={commentpost.name} picture={commentpost.image} date={commentpost.date}/>
            ) : <p style={{"color" : "black", "fontSize" : "15px"}}>Legyen ön az első aki hozzászól!</p>}
            <CommentToPost isReply={isReplyC} id={id} idComment={idCommentB}/>
        </section>
    );
}

export default Comments;