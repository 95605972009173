import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";
import { BiDownArrow } from "react-icons/bi";
import { collection, onSnapshot, orderBy, where, query } from "firebase/firestore";
import db from "../apis/firebase-blog-config.js";

function ProductFAQ({ id }) {

    const config = {
        animate: true,
        arrowIcon: "V",
        openOnload: 0,
        tabFocus: true,
        expandIcon: <BiDownArrow/>,
        collapseIcon: <BiDownArrow/>,
    };

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
      
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 50;
      
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active3");
          } else {
            reveals[i].classList.remove("active3");
          }
        }
      }
      
      window.addEventListener("scroll", reveal);

    const colReffaq = collection(db, `Product/${id}/FAQ`);
    
    const qfaq = query(colReffaq);

    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        window.scroll(0, 0);
        onSnapshot(qfaq, (snapshot) => {
            let posts = []
            snapshot.docs.forEach((doc) => {
                posts.push({...doc.data(), id: doc.id})
            })
            setFaqs(posts);
            console.log(posts)
    })
    }, [])

    const styles = {
        bgColor: 'transperent',
        titleTextColor: "black",
        rowTitleColor: "black",
        rowContentColor: 'grey',
        // arrowColor: "red",
    };

    const data = {
        title: "",
        rows: [
            {
                title: "Lorem ipsum dolor sit amet,",
                content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed tempor sem. Aenean vel turpis feugiat,
                  ultricies metus at, consequat velit. Curabitur est nibh, varius in tellus nec, mattis pulvinar metus.
                  In maximus cursus lorem, nec laoreet velit eleifend vel. Ut aliquet mauris tortor, sed egestas libero interdum vitae.
                  Fusce sed commodo purus, at tempus turpis.`,
            },
            {
                title: "Nunc maximus, magna at ultricies elementum",
                content:
                    "Nunc maximus, magna at ultricies elementum, risus turpis vulputate quam, vitae convallis ex tortor sed dolor.",
            },
            {
                title: "Curabitur laoreet, mauris vel blandit fringilla",
                content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
                Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
                Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
                Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
            },
            {
                title: "What is the package version",
                content: `current version is 1.2.1`,
            },
        ],
    };

    return(
        <section className="pfaqs reveal"> 
            <div className="pfaqsd-wrap">
                <div style={{"display" : "flex", "flexDirection" : "column", "alignItems" : "center"}}>
                    <h5 style={{"color" : "#A2D83E", "fontWeight" : "bold"}}>GYIK</h5>
                    <h1 style={{"textAlign" : "center", "fontWeight" : "bold", "width" : "100%"}}>Gyakran ismételt kérdések</h1>
                </div>
                <div className="faq-wrap">
                    <div className="faq-div">
                        <Faq
                            data={data}
                            styles={styles}
                            config={config}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProductFAQ;