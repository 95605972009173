import React from "react";

function Map() {
    return(
        <iframe
            className="map reveal"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2703.4423665007635!2d19.123952251248525!3d47.34476077906593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741e94abe60c0d7%3A0xa9a85c257d02b926!2sEnergotest%20Ltd%20Energotest%20Kft!5e0!3m2!1shu!2shu!4v1663942535348!5m2!1shu!2shu"
            width={600}
            height={450}
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
        />

    );
}

export default Map;