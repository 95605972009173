import React from "react";

function ProductDataTable() {

    const dataTable = [
        {
            name: "Modell",
            value: "DAESUNG DHAW 11K"
        },
        {
            name: "Fűtési hőteljesítmény ",
            value: "11 kW"
        },
        {
            name: "SCOP",
            value: "3.6"
        },
        {
            name: "Hűtési hőteljesítmény",
            value: "8.3 kW"
        },
        {
            name: "Fűtés névleges bemeneti teljesítmény",
            value: "3.5 kW"
        },
        {
            name: "Maximális bemeneti teljesítmény (biztosítási áramérték szempontjából)",
            value: "7.2 kW"
        },
        {
            name: "Névleges áramfelvétel",
            value: "5.1 A"
        },
        {
            name: "Maximális áramfelvétel",
            value: "10.5 A"
        },
        {
            name: "Hűtőközeg / Mennyiség R407C /",
            value: "3.2 kg"
        },
        {
            name: "GWP / CO2e",
            value: "1774 / 5.7 t"
        },
        {
            name: "Tesztelési nyomás [MPa]",
            value: "high: 2.64     low: 1.76"
        },
        {
            name: "Maximális üzemi nyomás [MPa]",
            value: "high: 2.4      low: 1.6"
        },
        {
            name: "Energiaellátás",
            value: "3x400V 50 Hz"
        },
    ] 

    const dataTableSizes = [
        {
            name: "(mélység x szélesség x magasság)",
            value: "379 x 979 x 1287"
        },
        {
            name: "Súly ",
            value: "105 kg"
        },
    ]

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
      
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 50;
      
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active3");
          } else {
            reveals[i].classList.remove("active3");
          }
        }
      }
      
      window.addEventListener("scroll", reveal);


    return(
        <section className="pdts reveal">
            <div className="pdt-wrap">
                <div style={{"marginBottom" : "40px"}}>
                    <h1 style={{"width" : "100%", "fontWeight" : "bold", "marginBottom" : "40px"}}>Adattábla</h1>
                    <div className="pdt-cont">
                        {dataTable ? dataTable.map((element) =>
                            <div className="dtline">
                                <div className="dtnhalf">
                                    <div style={{"fontWeight" : "bold"}}>{element.name}</div>
                                </div>
                                <div className="dtnhalf">
                                    <div>{element.value}</div>
                                </div>
                            </div>
                        ) : <p>Az adattábla nem található</p>}
                    </div>
                </div>
                <div>
                    <h1 style={{"width" : "100%", "fontWeight" : "bold", "marginBottom" : "40px"}}>Méretek</h1>
                    <div className="pdt-cont">
                        {dataTableSizes ? dataTableSizes.map((element) =>
                            <div className="dtline">
                                <div className="dtnhalf">
                                    <div style={{"fontWeight" : "bold"}}>{element.name}</div>
                                </div>
                                <div className="dtnhalf">
                                    <div>{element.value}</div>
                                </div>
                            </div>
                        ) : <p>Az adattábla nem található</p>}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProductDataTable;