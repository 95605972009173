import React from "react";

function CategoryDiv({ tag }) {

    return(
        <section className="cat-section">
            <div className="cat-div">
                <p>Kategória:</p>
                <h5>{tag}</h5>
            </div>
        </section>
    )
}

export default CategoryDiv;