import React, { useState, useEffect } from "react";
import { FaQuoteRight } from "react-icons/fa";
import ProductGrade from "./ProductGrade";
import { collection, onSnapshot, orderBy, where, query } from "firebase/firestore";
import db from "../apis/firebase-blog-config.js";

function ProductGreetings({ id }) {


    const [actual, setActual] = useState(0);
    const [toActual, setToActual] = useState(2);
    const [active, setActive] = useState(true)
    const [active2, setActive2] = useState(false)

    const colRefgreet = collection(db, `Product/${id}/Greetings`);
    
    const qgreet = query(colRefgreet);

    const [greetings, setGreetings] = useState([]);

    useEffect(() => {
        onSnapshot(qgreet, (snapshot) => {
            let posts = []
            snapshot.docs.forEach((doc) => {
                posts.push({...doc.data(), id: doc.id})
            })
            setGreetings(posts);
    })
    }, [id])
    
    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
      
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 50;
      
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active3");
          } else {
            reveals[i].classList.remove("active3");
          }
        }
      }
      
      window.addEventListener("scroll", reveal);

    const btns = greetings.length/2

    const switchGreet = () => {
        if (actual == 2 && toActual == 4) {
            setActual(0)
            setToActual(2);
            setActive2(false);
            setActive(true);
        }
    }
    const switchGreet2 = () => {
        if (actual == 0 && toActual == 2) {
            setActual(2)
            setToActual(4);
            setActive(false);
            setActive2(true);
        }
    }

    return(
        <section className="pgs reveal">
            <div>
                <div style={{"textAlign" : "center", "display" : "flex"}}>
                    <h1 style={{"width" : "100%", "marginBottom" : "40px", "fontWeight" : "bold"}}>Vélemények a termékről</h1>
                </div>
                <div className="greet-wrap gridgreet">
                    <div className="greet-div">
                        {greetings ? greetings.slice(actual, toActual).map((element) =>
                            <div className="greetitem-wrap" key={element.id}>
                                <div className="greetitem">
                                    <div style={{"backgroundColor" : "#D9D9D9", "padding" : "10px", "borderRadius" : "50px", "marginBottom" : "20px"}}>
                                        <FaQuoteRight size="25px" color="#A2D83E"/>
                                    </div>
                                    <div>
                                        <ProductGrade id={element.id} grade={element.rate}/>
                                    </div>
                                    <div>
                                        <h5 style={{"fontWeight" : "bold"}}>{element.greet}</h5>
                                    </div>
                                    <div>
                                        <h6>{element.name}</h6>
                                    </div>
                                    <div>
                                        <p style={{"color" : "black"}}>{element.job}</p>
                                    </div>
                                </div>
                            </div>
                        ) : <p>Erről a termékről még nem érkezett vélemény</p>}
                    </div>
                </div>
                <div className="stepper_greet_wrap">
                    <div className="stepper_greet">
                        <div className={active ? "active_greetings" : "unactive_greetings"} onClick={switchGreet}></div>   
                        <div className={active2 ? "active_greetings" : "unactive_greetings"} onClick={switchGreet2}></div> 
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProductGreetings;