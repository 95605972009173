import React, { useState } from "react";
import { BsReply } from "react-icons/bs";

function ReplyComment(props) {

    const [isReply, setIsReply] = useState(false);
    const [idComment, setIdComment] = useState('');

    const sendReply = (event) => {
        event.preventDefault();
        setIdComment(props.id);
        setIsReply(prevIsReply => !prevIsReply)
        props.onSubmit(isReply, idComment);
    }

    return(
        <section>
            <div className="comment-container">
                <div className="comment-img">
                    <img className="comment-pic" src={props.picture} alt="profilkep" />
                </div>
                <div className="comment-box">
                    <div className="comment-de-box">
                        <div className="comment-de">
                            <div className="comment-name">
                                <h6>{props.name}</h6>
                            </div>
                            <div className="comment-date">
                                <p>{props.date}</p>
                            </div>
                        </div>
                        <div className="comment-reply">  
                                <button onClick={sendReply} className="btn-reply">
                                    <BsReply/>Válasz
                                </button>
                        </div>
                    </div>
                    <div className="comment">
                        <p>{props.comment}</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ReplyComment;