import React from "react";

function Quote() {
    
    return(
        <section className="reveal">
            <div className="quote-div">
                <h1>“A zöld gondolkodás az egyetlen lehetőség, hogy megmentsük a bolygónkat.”</h1>
                <p>-LoremIpsum</p>
            </div>
        </section>
    );
}

export default Quote;