import React, { useState, useEffect } from "react";
import "../style/MadeByBlog.css";
import { addDoc, collection } from "firebase/firestore";
import db, {auth} from '../apis/firebase-blog-config';
import {useAuthState} from 'react-firebase-hooks/auth';
import { toast } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css';

function CommentToPost({id, isReply}) {

    const user = useAuthState(auth);
    const [comment, setComment] = useState('');
    const [name, setName] = useState("");
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
    const [image, setImage] = useState("");
    const postsCollectionRef = collection(db, `posts/${id}/Comments`);

    const sendComment = async () => {
        if (comment !== "") {
            await addDoc(postsCollectionRef, {
                comment,
                name,
                date,
                image,
            });   
            console.log(isReply);
            setComment("");
            alertSuccess();
        }
    };

    useEffect(() => {
        if (user[0] !== null) {
            setName(user[0].displayName);
            setImage(user[0].photoURL);
        }
    }, [user])

    
    const alertSuccess = () => toast.success('Hozzászólás elküldve!');

    const Send = () => {
        if (user) {
            sendComment();
        } else {
            const alertWarning = () => toast.warning('A hozzászólás funkció csak a bejelentkezett felhasználóknak elérhető');
            alertWarning();
        }
    }

    return(
        <section>
            <div className="ctp-sd">
            <h1 className="ch1">Szólj hozzá!</h1>
                <div className="ctp-sdd">
                    <div className="ctp-sddd1">
                            <textarea 
                            className="tfctp" 
                            name="comment" 
                            id="" 
                            cols="30" 
                            rows="10"
                            placeholder="Mondja el mit gondol a tartalommal kapcsolatosan."
                            onChange={(event) => {
                            setComment(event.target.value);
                            }}
                            required
                            ></textarea>
                            <div className="ctp-sddd2">
                                <button type="submit" onClick={Send} className="btn-filled bn">Közzététel</button>
                            </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CommentToPost;