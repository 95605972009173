import React, { useState, useEffect } from "react";
import Nav from "./components/Nav";
import Blogpost from "./components/BlogPostWidget";
import SearchBar from "./components/SearchBar";
import TagsWidget from "./components/TagsWidget";
import PopularBlog from "./components/PopularBlogs";
import { Link } from "react-router-dom";
import "./style/Blog.css"
import NewsletterWidget from "./components/NewsletterWidget";
import CategoryDiv from "./components/categorydiv";
import Footer from "./components/Footer";
import SearchFor from "./components/searchFor";
import db from "./apis/firebase-blog-config.js";
import { collection, onSnapshot, where, query } from "firebase/firestore";
import { IoIosArrowForward } from "react-icons/io";
import { BsBoxArrowUpLeft } from "react-icons/bs";
import { useParams, useNavigate } from "react-router-dom";
import { BsBoxArrowInDownRight } from "react-icons/bs";

function Blog() {
    
    const [openMobileWidget, setOpenMobilewidget] = useState(false)

    const openWidget = () => {
      setOpenMobilewidget(current => !current)
    }
    const [queryValue, setQueryValue] = useState("");
    const [used, setUsed] = useState(false);
    const [posts, setPosts] = useState([]);
    const getSearch = (search) => {
        setQueryValue(search);
        setUsed(true);
        setUsedTag(false);
        setQ(query(colRef, where("title_query", "array-contains", search)));
        setOpenMobilewidget(false)
    };
    const navigate = useNavigate();
    
    const [usedTag, setUsedTag] = useState(false);
    const [tagQueryValue, setTagQueryValue] = useState();

    const getTag = (tagQuery) => {
        setTagQueryValue(tagQuery);
        setUsedTag(true);
        setUsed(false);
        setQ(query(colRef, where("tags", "array-contains", tagQuery)));
        setOpenMobilewidget(false)
    };
    
    const colRef = collection(db, "posts");
    
    const [q, setQ] = useState(query(colRef))


    useEffect(() => {
        window.scroll(0, 0);
        onSnapshot(q, (snapshot) => {
            let posts = []
            snapshot.docs.forEach((doc) => {
                posts.push({...doc.data(), id: doc.id})
            })
            setPosts(posts);
    })
    }, [q])

    const [comments, setComments] = useState([]);

    const getComments = () => {
        if (posts) {
            const colRef2 = collection(db, `posts/${posts.id}/Comments`);
        const q2 = query(colRef2);
        onSnapshot(q2, (snapshot) => {
          let comment = []
          snapshot.docs.forEach((doc) => {
              comment.push({...doc.data(), id: doc.id})
          })
          setComments(comment.length);
        })
        }
        return comments;
    }
    

    


    return(
        <>
            <Nav></Nav>
            <div className="outbid w3-animate-top">
                <div className="bid">
                    <h1>Olvassa el legfrisebb tartalmainkat</h1>
                </div>
                <div className="covernav">
                        <Link className="covernavp" to="/" alt="Vissza a kezdőlapra">Kezdőlap</Link><p className="covernavp" ><IoIosArrowForward/></p><p className="covernavp bold" >Blog</p>
                </div>
            </div>
            {used ? <SearchFor query={queryValue}/> : null}
            {usedTag ? <CategoryDiv tag={tagQueryValue}/> : null}
            <section className="blog-div">
                <div className="NewestBlogFullDiv w3-animate-bottom">
                    {posts.length > 0 ? posts.map((posts) =>
                        <Blogpost
                            seen={posts.seen}
                            title={posts.title}
                            intro={posts.introText}
                            coverimg={posts.coverimg}
                            publishDate={posts.date_Published}
                            slug={posts.slug}
                            tags={posts.tags}
                            id={posts.id}
                            key={posts.id}
                            comments={comments.length}
                        />
                    ) : <h1 className="nfph1">A tartalom nem található</h1>}
                </div>
                <div className="Blogwidget-div w3-animate-right">
                    <SearchBar onSubmit={getSearch}/>
                    <TagsWidget onSubmit={getTag}/>
                    <PopularBlog/>
                    <NewsletterWidget/>
                </div>
                <div className="mobile-arrow" onClick={openWidget}>
                    {openMobileWidget ? <BsBoxArrowInDownRight/> : <BsBoxArrowUpLeft color="black"/>}
                </div>
                {openMobileWidget ? 
                    <div className="mobileBlogwidget-div w3-animate-bottom">
                        <div className="innerdivmobwid">
                            <SearchBar onSubmit={getSearch}/>
                            <TagsWidget onSubmit={getTag}/>
                            <PopularBlog/>
                            <NewsletterWidget/>
                        </div>
                    </div>
                : null }
            </section>
            <Footer/>
        </>
    );
}

export default Blog;