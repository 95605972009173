import React from "react";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import { useNavigate } from 'react-router-dom';


function NotFoundPage() {
    
    const navigate = useNavigate();

    return(
        <section className="nfbi">
            <Nav/>
            <section className="nfp-sec">
                <div className="nfp-div">
                    <h1>404</h1>
                    <h3>Sajnáljuk, az oldal nem található</h3>
                    <div>                        
                        <button className="btn-404" onClick={() => navigate("/")}>Vissza a kezdőlapra</button>
                        <button className="btn-404" onClick={() => navigate(-1)}>Vissza az előző lapra</button>
                    </div>
                </div>
            </section>
            <Footer></Footer>        
        </section>
    );
}

export default NotFoundPage;