import React from "react";
import "../style/MadeByBlog.css";

function MadeByBlog({ author, image, job }) {
    return(
        <section>
            <div className="made-by-div">
                <div className="creator-de">
                    <div className="creator-img">
                        <img src={image} alt="" />
                    </div>
                    <div className="creator">
                        <h3>{author}</h3>
                        <h6>{job}</h6>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MadeByBlog;