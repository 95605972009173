import React, { useEffect, useState } from "react";
import SearchBar from "./SearchBar";
import TagsWidget from "./TagsWidget";
import PopularBlog from "./PopularBlogs";
import NewsletterWidget from "./NewsletterWidget";
import "../style/slug.css";
import Footer from "./Footer";
import Nav from "./Nav";
import BlogPostData from "./BlogPostData";
import MadeByBlog from "./MadeByBlog";
import PrevNextPostGEB from "./PrevNextPostGEB";
import { useParams, Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import db from "../apis/firebase-blog-config.js";
import { collection, onSnapshot, where, query, } from "firebase/firestore";

function GEBPost() {

  const { id } = useParams();


    const [post, setPost] = useState([]);
    
    const colRef = collection(db, "GEB");

    const q = query(colRef, where("slug", "==", id));

    useEffect(() => {
      window.scrollTo(0, 0)
      onSnapshot(q, (snapshot) => {
        let posts = []
        snapshot.docs.forEach((doc) => {
            posts.push({...doc.data(), id: doc.id})
        })
        setPost(posts[0]);
      })
      
    }, [id])
  


  return (
    <section>
      <Nav></Nav>
      <Link to="/" className="navtohome"><IoIosArrowBack/>Vissza a kezdőlapra</Link>
      <img src={post.cover_image} className="GEBPI" alt="" srcset="" />
      <section className="slug-ss">
        <div className="slug-ssd2">
          <div className="Blogpost-title">{post.episode} - {post.title}</div>
          <BlogPostData publishDate={post.date_Published} seen={post.seen} comments={0}/>
          <div className="lineslug"></div>
          <div className="Blogpost-content" dangerouslySetInnerHTML={{__html:post.content}}>
          </div>
          <MadeByBlog author={post.author} job={post.job}/>
          <PrevNextPostGEB/>
        </div>
        <div className="Blogwidget-div">
          <SearchBar/>
          <TagsWidget/>
          <PopularBlog/>
          <NewsletterWidget/>
        </div>
      </section>
      <Footer/>
    </section>
  );
}

export default GEBPost;