import React from "react";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

function AboutIntro() {
    return(
        <section className="About-intro w3-animate-top">
            <div className="bid">
                    <h1>Tudjon meg többet rólunk</h1>
            </div>
            <div className="covernav2">
                        <Link className="covernavp" to="/" alt="Vissza a kezdőlapra">Kezdőlap</Link><p className="covernavp" ><IoIosArrowForward/></p><p className="covernavp bold" >Rólunk</p>
            </div>
        </section>
    );
}

export default AboutIntro;