import React, { useState, useEffect } from "react";
import Blogpost from "./BlogPostWidget";
import { useNavigate } from "react-router-dom";
import db from "../apis/firebase-blog-config.js";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { ReactComponent as Svg1 } from "../assets/Vector 3.svg";
import { ReactComponent as Svg2 } from "../assets/Vector 4.svg";
import { ReactComponent as Svg3 } from "../assets/Vector 5.svg";

function NewestBlogPosts() {
    const navigate = useNavigate();
    const [posts, setPosts] = useState([]);
    const q = (query(collection(db, "posts"), orderBy("date_Published", "desc")));

    useEffect(() => {
        const asyncFn = async () => {
            const querySnapshot = await getDocs(q);
            setPosts(querySnapshot.docs.map(it => it.data()));
            return(posts)
        };
        asyncFn();
    }, [])

    

    return(
        <section id="newestblog">
            <h2>Legfrisebb tartalmaink</h2>
            <Svg1 className="BPIHSVG1"/>
            <div className="NewestBlogFullDiv reveal homenb">
                {posts.length > 0 ? posts.slice(0, 6).map((post) =>
                    <Blogpost 
                        seen={post.seen}
                        title={post.title}
                        intro={post.introText}
                        coverimg={post.coverimg}
                        publishDate={post.date_Published}
                        slug={post.slug}
                        tags={post.tags}
                        key={post.id}
                        id={post.id}
                        comments="0"
                    />
                ) : <h3 className="nfph1">A tartalom nem található</h3>}
            </div>
            <Svg2 className="BPIHSVG2"/>
            <Svg3 className="BPIHSVG3"/>
            <button onClick={() => navigate("/Blog")} className="btn-outline toblogbtn">Tovább a blogra...</button>
        </section>
    );
}

export default NewestBlogPosts;