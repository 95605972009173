import React, { useState } from "react";
import "../style/TagsWidget.css";

function TagsWidget(props) {

        const [tags, setTags] = useState(["Interjú", "Cég", "Zöld", "Energetika", "Megújulás", "Föld"]);
        
        const Pick = (event) => {
            event.preventDefault();
            props.onSubmit(event.target.value);
        };

    return(
        <section className="Widget-cont">
            <div className="Widget-box">
                <div className="title">
                    <h4 className="widget-title">Címkék</h4>
                </div>
                <div className="bar-tags">
                    <form action="">
                        {tags ? tags.map((tag) =>
                            <input key={tag} onClick={Pick} type="submit" className="tag-box" value={tag}/>
                        ) : null}
                    </form>
                </div>
            </div>
        </section>
    );
}

export default TagsWidget;