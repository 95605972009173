import React, { useState, useEffect } from "react";
import "../style/Footer.css"
import { HiMail } from "react-icons/hi";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaMapMarker } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
import { CgWebsite } from "react-icons/cg";
import db from "../apis/firebase-blog-config.js";
import firebase from 'firebase/compat/app';
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { toast } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css';
import { Link } from "react-router-dom";

function Footer() {

    const [sent, setSent] = useState(false);
    const [input, setInput] = useState("");
    const [once, setOnce] = useState(0);
    const inputHandler = (e) => {
      setInput(e.target.value);
    };

    const [emails, setEmails] = useState();
    const submitHandler = (e) => {
        e.preventDefault();
        const colRef2 = collection(db, "newsletter-emails")
        const q2 = query(colRef2 , where("email", "==", input)); 
        
        onSnapshot(q2, (snapshot) => {
            let emails2 = []
            snapshot.docs.forEach((doc) => {
                emails2.push({...doc.data(), id: doc.id})
            })
            setEmails(emails2);
            console.log(emails)
            setOnce(prev => prev + 1);
        })
        
    };

      useEffect(() => {
        if (emails) {
            if (emails.length !== 0 && sent == false) {
                const alertWarning = () => toast.warning('Az email már regisztrálva van.');
                alertWarning();
            } else {
                if (input !== "") {
                    db.collection("newsletter-emails").add({
                        email: input,
                        time: firebase.firestore.FieldValue.serverTimestamp(),
                      });
                      setInput("");
                      const alertSuccess = () => toast.success('Sikeresen feliratkoztál a hírlevelünkre!');
                      alertSuccess();
                      setSent(true)
                      console.log("ok")
                }  
            }   
        }
      }, [once])

      const [newPosts, setNewPosts] = useState([]);
      
      const colRef = collection(db, "posts");
  
      const q = query(colRef, orderBy("date_Published", "asc"));
  
      useEffect(() => {
          onSnapshot(q, (snapshot) => {
              let posts = []
              snapshot.docs.forEach((doc) => {
                  posts.push({...doc.data(), id: doc.id})
              })
              setNewPosts(posts.slice(0, 2));
          })
      }, [])

    return(
        <section>
            <footer>
                {sent == false ? 
                <div className="footer-newsletter-div">
                    <h1>Iratkozzon fel hírlevelünkre!</h1>
                    <form onSubmit={submitHandler} className="footnewsform" action="">
                            <input 
                            id="email"
                            type="email" 
                            name='reply_to'
                            placeholder='Email cím...'
                            value={input}
                            onChange={inputHandler}
                            required
                            />
                        <button type="submit" className="btn-filled">Feliratkozom</button>
                    </form>
                </div>
                :
                <div className="footer-newsletter-div">
                    <h1 className="succesh1">Sikeresen feliratkoztál a hírlevelünkre!</h1>
                </div>
                }
                
                <div className="footer-links-div">
                    <div className="footer-link-1">
                        <div className="box-link-div">
                            <h4>Elérhetőségek</h4>
                            <div className="link-div">
                                <div className="links-div">
                                    <HiMail color="#A2D83E"></HiMail>
                                    <p>greennewbrain@energotest.hu</p>
                                </div>
                                <div className="links-div">
                                    <BsFillTelephoneFill color="#A2D83E"></BsFillTelephoneFill>
                                    <p>+36 70 574 1272</p>
                                </div>
                                <div className="links-div"> 
                                    <FaMapMarker color="#A2D83E"></FaMapMarker>
                                    <p>Dunaharaszti, Gomba u. 4., 2330</p>
                                </div>
                            </div>
                        </div>
                        <div className="box-link-div">
                            <h4>Szociális média</h4>
                            <div className="link-div">
                                <div className="links-div">
                                    <FaFacebookSquare color="#A2D83E"></FaFacebookSquare>
                                    <Link href="">Facebook</Link>
                                </div>
                                <div className="links-div">
                                    <FaLinkedinIn color="#A2D83E"></FaLinkedinIn>
                                    <Link href="">LinkedIn</Link>
                                </div>
                                <div className="links-div">
                                    <CgWebsite color="#A2D83E"></CgWebsite>
                                    <Link to="http://www.energotest.hu/" target="_blank">Energotest</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-link-div">
                        <h4>Legfrisebb tartalmaink</h4>
                        <div className="blog-news">
                            {newPosts.length > 0 ? newPosts.map((posts) =>
                                <div className="blog-new" key={posts.id}>
                                    <p>{posts.datePublished}</p>
                                    <Link to={`/Blog/${posts.slug}`}>{posts.title}</Link>
                                </div>
                            ) : <p>A tartalom nem található</p>}
                            <div className="blog-new-btn">
                                <Link to="/Blog" className="to-blog"><BsArrowRight color="#A2D83E" size="20px"/>Tovább a blogra</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="footer-rights">
                    <p>Copyright © 2022 Green New Brain. Minden jog fenttartva.</p>
                    <Link to="/">Általános Szerződési Feltételek</Link>
                    <Link to="/">Adatvédelmi irányelvek</Link>
            </div>
        </section>
    )
}

export default Footer;