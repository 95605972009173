import React from "react";
import "../style/Intro.css";
import { AiOutlineDown } from "react-icons/ai";
import { Link } from "react-router-dom";

function Intro() {
    return(
        <section className="intro-section">
            <h1 className="w3-animate-left">Green New Brain</h1>
            <h5 className="w3-animate-left">A zöld gondolkodás az egyetlen lehetőség, hogy megmentsük a bolygónkat.</h5>
            <Link to="/Rolunk"><button className="w3-animate-left btn-filledhi">Rólunk</button></Link>
            <Link to="/Kapcsolatfelvetel"><button className="w3-animate-left btn-outlinehi">Kapcsolatfelvétel</button></Link>
            <a href="#newestblog" className="scroll-text ab">Legfrisebb tartalmaink<AiOutlineDown/></a>
        </section>
    );
}

export default Intro;