import React, {useState} from "react";
import "../style/Normalnav.css";
import { NavLink, Link } from "react-router-dom";
import Logo from "../logo-removebg-preview.png";
import { CgProfile } from "react-icons/cg";
import Login from "./Login";
import ProfileData from "./ProfileData";
import {auth} from '../apis/firebase-blog-config';
import {useAuthState} from 'react-firebase-hooks/auth';

function Normalnav() {

        const [user] = useAuthState(auth);

        const [colorChange, setColorchange] = useState(false);
        const changeNavbarColor = () =>{
           if(window.scrollY >= 1){
             setColorchange(true);
           }
           else{
             setColorchange(false);
           }
        };
        window.addEventListener('scroll', changeNavbarColor);

        const [openProfile, setOpenProfile] = useState(false);

        const handleClick = event =>{
            if (user) {
                setOpenProfile(current => !current);
            }
        }

    return(
        <section className="normalnav-section">
            <header>
                <div className={colorChange ? 'colorChange' : 'nav-div'}>
                    <div>
                        <Link to="/"><img src={Logo} height="40px" alt="logo" /></Link>
                    </div>
                    <div className="full-nav-link">
                        <div className="nav-div-ul">
                            <ul className={colorChange ? 'nav-ul' : 'colorChange-navul'}>
                                    <NavLink 
                                    to="/" 
                                    activeclassname="active"
                                    className="nava"
                                    end
                                    >
                                        Kezdőlap
                                    </NavLink>
                                    <NavLink
                                    activeclassname="active"
                                    className="nava"
                                    to="/Blog">
                                        Blog
                                    </NavLink>
                                    {/*<NavLink 
                                    activeClassName="navbar__link--active"
                                    to="/szolgáltatások">
                                        Szolgáltatásaink
                                    </NavLink>*/}
                                    <NavLink 
                                    activeclassname="active"
                                    className="nava"
                                    to="/Rolunk">
                                        Cégünk
                                    </NavLink>
                                    <NavLink 
                                    activeclassname="active"
                                    className="nava"
                                    to="/Kapcsolatfelvetel">
                                        Kapcsolatfelvétel
                                    </NavLink>
                                {user ? 
                                            <CgProfile 
                                                style={{"cursor": "pointer"}} 
                                                onClick={handleClick}
                                                size="22px"
                                            />
                                :
                                    <Login/>
                                }
                            </ul>
                            {openProfile && user ?  
                                        <ProfileData 
                                            id="togglePD"
                                        /> 
                            : null}
                        </div>
                    </div>
                </div>
            </header>
        </section>

    )
}

export default Normalnav;