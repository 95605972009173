import React, { useEffect, useState } from "react";
import "../style/PopularBlogs.css";
import { Link } from "react-router-dom";
import db from "../apis/firebase-blog-config.js";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";

function PopularBlog() {
    const [popPosts, setPopPosts] = useState([]);
    
    const colRef = collection(db, "posts");

    const q = query(colRef, orderBy("seen", "desc"));

    useEffect(() => {
        onSnapshot(q, (snapshot) => {
            let posts = []
            snapshot.docs.forEach((doc) => {
                posts.push({...doc.data(), id: doc.id})
            })
            setPopPosts(posts.slice(0, 3));
        })
    }, [])



    return(
        <section className="Widget-cont">
            <div className="Widget-box">
                <div className="title">
                    <h4 className="widget-title">Legnépszerűbb tartalmaink</h4>
                </div>
                <div className="bar-pop">
                    {popPosts.length > 0 ? popPosts.slice(0, 3).map((post) =>
                        <div className="pre-blog-box" key={post.id}>
                            <div className="pre-blog-cover">
                                <img className="pbc-img" src={post.coverimg} alt="" srcSet=""/>
                            </div>
                            <div className="pre-blog-detalis">
                                <p className="date">{post.date_Published}</p>
                                <Link className="pop-title"  to={`/Blog/${post.slug}`}>{post.title}</Link>
                            </div>
                            <div className="line22"></div>
                        </div>
                    ) : <p style={{"color" : "black", "fontSize" : "14px", "marginBottom" : "0px"}}>A tartalom nem található</p>}
                </div>
            </div>
        </section>
    );
}

export default PopularBlog;