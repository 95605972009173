import React, { useState, useEffect, useRef  } from "react";
import { AiFillStar } from "react-icons/ai";
import { AiOutlineStar } from "react-icons/ai";

function ProductGrade({ grade }) {

    const [stars, setStars] = useState([]);

    const counter = useRef(0);
    const effectCalled = useRef(false);

    useEffect(() => {
        if (effectCalled.current) return;
        if (grade !== undefined) {
            for (let index = 1; index <= 5; index++) {
                if (index <= grade) {
                    setStars((state)=>[...state, {id: index, star: <AiFillStar size="25px" color="#F5F03C"/>}])
                }
                if (index > grade) {
                    setStars((state)=>[...state, {id: index, star: <AiOutlineStar size="25px" color="#F5F03C"/>}])
                }
            }
        }
        counter.current += 1;
        effectCalled.current = true;
    }, []);

    return(
        <section className="pgs2">
            <div className="pgssd">
                {stars ? stars.map((star) =>
                    <div>
                        {star.star}
                    </div>
                ) : <p>Nem található értékelés</p>}
            </div>
        </section>
    );
}

export default ProductGrade;