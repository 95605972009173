import React from "react";
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Blog from "./Blog";
import Contact from "./Contact";
import Home from "./Home";
import NotFoundPage from "./404Page";
import About from "./about";
import Slug from "./components/slug";
import CreatePost from "./components/CreatePost";
import Services from "./components/Services";
import GEBPost from "./components/GEBPost";
import ProductPage from "./components/ProductPage";


function App() {
    


    return(
        <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<Home/>}/>
                    <Route path="/Blog" element={<Blog/>}/>
                    <Route path="/Kapcsolatfelvetel" element={<Contact/>}/>
                    <Route path="/Rolunk" element={<About/>}/>
                    <Route path="/Blog/:id" element={<Slug/>}/>
                    <Route path="/GEB/:id" element={<GEBPost/>}/>
                    <Route path="/Termek/:id" element={<ProductPage/>}/>
                    <Route path="/Posztkeszites" element={<CreatePost/>}/>
                    <Route path="/Szolgaltatasok" element={<Services/>}/>
                    <Route path="*" element={<NotFoundPage/>}/>
                </Routes>
        </BrowserRouter>
    )
}

export default App;
