import React from "react";
import { ReactComponent as First } from "../assets/01.svg";
import { ReactComponent as Second } from "../assets/02.svg";
import { ReactComponent as Third } from "../assets/03.svg";
import { FaWrench } from "react-icons/fa";
import { RiInstallFill } from "react-icons/ri";
import { GrServices } from "react-icons/gr";

function About2Sec() {

    const content = [
        {
            title: "Hőszivattyú",
            paragraph: "A hatékony és környezetbarát energiaforrás",
            number: <First className="svg"/>,
            icon: <GrServices size="60px"/>,
            key: 1
        },
        {
            title: "Telepítés",
            paragraph: "Egy minőségi megoldás a fenntartható és gazdaságos hőellátásra",
            number: <Second className="svg"/>,
            icon: <RiInstallFill size="60px"/>,
            key: 2
        },
        {
            title: "Karbantartás",
            paragraph: "A hosszú élettartam és a magas hatékonyság biztosítása a rendszeres karbantartással",
            number: <Third className="svg"/>,
            icon: <FaWrench size="60px"/>,
            key: 3
        }
    ]

    return(
        <section id="about">
            <div className="a2sd">
                <h1 className="w3-animate-bottom">
                A vállalkozások sikere érdekében - korszerű technológiák, szakértelmi tudás, egyéni megoldások
                </h1>
                <div className="a2sdd">
                    <div className="a2sddd reveal">
                        {content ? content.map((cont) =>
                            <div key={cont.key} className="a2sdddd">
                                {cont.number}
                                {cont.icon}
                                <h3>{cont.title}</h3>
                                <p>{cont.paragraph}</p>
                            </div>
                        ) : "a tartalom nem található"}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About2Sec;