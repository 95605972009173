import React, { useState } from 'react';
import { useEffect } from 'react';
import {auth , provider}  from '../apis/firebase-blog-config';
import { toast } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css';
import firebase from 'firebase/compat/app';

const Login = () => {



    const signin = () => {
            auth.signInWithPopup(provider).catch(alert);
    }

    const alertSuccess = () => toast.success('Sikeres bejelentkezés!');

    useEffect(() => {
        const listener = firebase.auth().onAuthStateChanged((authUser) => {
          if (authUser) {
            alertSuccess();
          }
        });
    
        return () => listener?.();
    }, [firebase]);


    const [colorChange, setColorchange] = useState(false);
        const changeNavbarColor = () =>{
           if(window.scrollY >= 1){
             setColorchange(true);
           }
           else{
             setColorchange(false);
           }
        };
        window.addEventListener('scroll', changeNavbarColor);

      
    return (
        <div>
            <div>
                <button className={colorChange ? 'siwgbtn' : 'siwgbtnw'}
                onClick={signin}>Bejelentkezés</button>
            </div>
        </div>
    );
}
  
export default Login;