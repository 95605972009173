import React, { useState, useRef, useEffect } from "react";
import Counterbg from "../assets/counter-bg.png";
import Bookwidget from "./Bookwidget";
import { ReactComponent as Svg1 } from "../assets/Vector 1.svg";
import { ReactComponent as Svg2 } from "../assets/Vector 2.svg";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import db from "../apis/firebase-blog-config.js";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";

function HGEB() {

    const ref = useRef(null);
    const scroll = (scrollOffset) => {
        ref.current.scrollLeft += scrollOffset;
    };

    const colRef = collection(db, "GEB");
    
    const [q, setQ] = useState(query(colRef, orderBy("slug", "asc")))

    const [episodes, setEpisodes] = useState();

    useEffect(() => {
        window.scroll(0, 0);
        onSnapshot(q, (snapshot) => {
            let posts = []
            snapshot.docs.forEach((doc) => {
                posts.push({...doc.data(), id: doc.id})
            })
            setEpisodes(posts);
    })
    }, [])


    return(
        <section id="greenenergybook">
            <img className="cbg" src={Counterbg} alt="" />
            <div>
                <div className="step-title-box">
                    <h2>A felhasználói energiamegtakarítás egyszerű cselekvő gondolkodásának kézikönyve</h2>
                    <div className="stepperwrap">
                        <div className="stepper-box">
                                <AiOutlineArrowLeft 
                                onClick={() => scroll(-480)} 
                                id="stepleft" 
                                className="step-box" 
                                color="A2D83E" 
                                size="40px"/>
                            <div className="vertical-line"></div>
                                <AiOutlineArrowRight
                                className="step-box" 
                                id="stepright"
                                onClick={() => scroll(480)}
                                color="A2D83E" 
                                size="40px"/>
                        </div>
                    </div>
                </div>
                <div className="BooksWidget-box" ref={ref}>
                    {episodes ? episodes.map((record, i) => 
                        <Bookwidget id={record.slug} id2={record.id} key={record.id} title={record.title} image={record.cover_image} intro={record.cover_title} />
                    ) : "A Zöld Energia Kézikönyv nem található"}
                    <Svg1 className="svg1"/>
                    <Svg2 className="svg2"/>
                </div>
            </div>
        </section>
    );
}

export default HGEB;