import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import 'firebase/compat/auth';

  const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCG1i6RTMi9lKyZFAJZzgwAt4l17jrPRhI",
    authDomain: "greennewbrain-blog.firebaseapp.com",
    databaseURL: "https://greennewbrain-blog-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "greennewbrain-blog",
    storageBucket: "greennewbrain-blog.appspot.com",
    messagingSenderId: "33066557859",
    appId: "1:33066557859:web:143fa3ce3e03e4d502ba16",
    measurementId: "G-CD9PZJ42CQ"
  });

  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseApp);
  }

const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
const db = firebaseApp.firestore();
export {auth , provider};
export default db; 