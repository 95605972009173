import React from "react";
import { BsFillClockFill } from "react-icons/bs";
import { BsPinMapFill } from "react-icons/bs";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import "../style/Contact.css";

function ContactCards() {
    return(
        <section className="cards">
            <div className="cards-box">
                <div className="card-box w3-animate-left">
                    <div className="card-svgbox">
                        <BsFillClockFill color="#A2D83E" size="40px"/>
                    </div>
                    <div className="card-det">
                        <h5>Nyitvatartási idő</h5>
                        <p>Hétfő - Péntek: 08:00 - 18:30</p>
                    </div>
                </div>
                <div className="card-box-rev w3-animate-right">
                    <div className="card-det">
                        <h5>Telefonszám</h5>
                        <p>+36 70 574 1272</p>
                    </div>
                    <div className="card-svgbox-rev">
                        <BsFillTelephoneFill color="#A2D83E" size="40px"/>
                    </div>
                </div>
                <div className="card-box w3-animate-left">
                    <div className="card-svgbox">
                        <BsPinMapFill color="#A2D83E" size="40px"/>
                    </div>
                    <div className="card-det">
                        <h5>Cím</h5>
                        <p>Dunaharaszti, Gomba u. 4., 2330</p>
                    </div>
                </div>
                <div className="card-box-rev w3-animate-right">
                    <div className="card-det">
                        <h5>Email</h5>
                        <p>greennewbrain@energotest.hu</p>
                    </div>
                    <div className="card-svgbox-rev">
                        <MdEmail color="#A2D83E" size="40px"/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactCards;