import React, { useState } from "react";
import "../style/SearchBar.css"

function SearchBar(props) {

    const [search, setSearch] = useState("");
    const [updated, setUpdated] = useState(search);

    const handleChange = (event) => {
        setSearch(event.target.value);
      };
    const handleSubmit = (event) => {
        event.preventDefault();
        setUpdated(search);
        props.onSubmit(search);
    };

    return(
        <section className="Widget-cont">
            <div className="Widget-box">
                <div className="title">
                    <h4 className="widget-title">Kereső</h4>
                </div>
                <div className="bar">
                    <form onSubmit={handleSubmit}>
                        <input className="search-input" onChange={handleChange} value={search} required type="text" name="text"/>
                        <input className="btn-input" type="submit" placeholder="Keresés" value="Keresés"/>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default SearchBar;