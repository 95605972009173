import React, { useState } from "react";
import { send } from 'emailjs-com';
import { HiMail } from "react-icons/hi";
import { ToastContainer, toast } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css';

function ContactMe() {

    const [toSend, setToSend] = useState({
        from_name: '',
        message: '',
        reply_to: '',
      });
    
      const onSubmit = (e) => {
        e.preventDefault();
        send(
            'service_5bhoa9n',
            'template_k70uuuh',
            toSend,
            '3dk-isnh6EmZfcO_P'
          )
            .then((response) => {
              const alertSuccess = () => toast.success('A levél elküldése sikeres!');
              alertSuccess();
            })
            .catch((err) => {
              console.log('FAILED...', err);
              const alertWarning = () => toast.warning('A levél elküldése sikertelen!', err);
              alertWarning();
            });
      };
    
      const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
      };

      function reveal() {
        var reveals = document.querySelectorAll(".reveal");
      
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 50;
      
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active3");
          } else {
            reveals[i].classList.remove("active3");
          }
        }
      }
      
      window.addEventListener("scroll", reveal);

    return(
       <section>
            <div className="contactme-box reveal">
                <div className="contactme-de-box">
                  <HiMail className="icon-contact" size="500px"/>
                    <div className="cmdeb2">  
                        <h4>Küldjön nekünk emailt!</h4>
                        <div className="d1"></div>
                        <div className="d2"></div>
                        <p className="contact-me-p">Irja le miben segíthetünk és szakembereink megoldják</p>
                    </div>
                </div>
                <div className="contactme-form-box">
                    <form onSubmit={onSubmit} className="contactme-form">
                        <input 
                        className="inp inp1" 
                        type='text'
                        name='from_name'
                        placeholder='Név...'
                        value={toSend.from_name}
                        onChange={handleChange}
                        required
                        />
                        <input 
                        className="inp inp2" 
                        type="email" 
                        name='reply_to'
                        placeholder='Email cím...'
                        value={toSend.reply_to}
                        onChange={handleChange}
                        required
                        />
                        <textarea 
                        type='text'
                        name='message'
                        className="inp-text"
                        placeholder="Miben segíthetünk?"
                        value={toSend.message}
                        onChange={handleChange}
                        />
                        <button className="inp-btn" type="submit" >Küldés</button> 
                    </form>
                </div>
            </div>
       </section>
       
    );
}

export default ContactMe;