import React, { useEffect } from "react";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import AboutSection from "./components/AboutSection";
import Quote from "./components/quote";
import "./style/About.css";
import About2Sec from "./components/about2sec";
import About3Sec from "./components/about3sec";
import About4Sec from "./components/about4sec";
import AboutIntro from "./components/AboutIntro";
import { ReactComponent as Abvec1 } from "./assets/About Vector 1.svg";
import AboutGreetings from "./components/Aboutgreetings";

function About() {

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
      
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 100;
      
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active3");
          } else {
            reveals[i].classList.remove("active3");
          }
        }
    }

      useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      
      window.addEventListener("scroll", reveal);

      function revealRight() {
        var reveals = document.querySelectorAll(".revealRight");
      
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 100;
      
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active4");
          } else {
            reveals[i].classList.remove("active4");
          }
        }
      }
      
      window.addEventListener("scroll", revealRight);

    return(
        <>
            <Nav></Nav>
            <AboutIntro/>
            <About2Sec/>
            <Abvec1 className="abvec1"/>
            <AboutSection/>
            <Quote/>
            <About3Sec/>
            <About4Sec/>
            <AboutGreetings/>
            <Footer/>
        </>
    );
}

export default About;