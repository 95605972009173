import React from "react";

function ProductDetCard({ dt1, dt2, dt3, dt4, ill1, ill2, ill3, ill4 }) {

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
      
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 50;
      
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active3");
          } else {
            reveals[i].classList.remove("active3");
          }
        }
      }
      
      window.addEventListener("scroll", reveal);

    return(
        <section>
            <div className="pdi2d-wrap reveal">
                <div className="pdi2d">
                    <div className="pdiditem">
                            <img className="pdcill" src={ill1} alt="" srcset="" />
                        <div className="itemdet" dangerouslySetInnerHTML={{__html:dt1}}></div>
                    </div>
                    <div className="pdiditem">
                            <img className="pdcill" src={ill2} alt="" srcset="" />
                        <div className="itemdet" dangerouslySetInnerHTML={{__html:dt2}}></div>
                    </div>
                    <div className="pdiditem">
                            <img className="pdcill" src={ill3} alt="" srcset="" />
                        <div className="itemdet" dangerouslySetInnerHTML={{__html:dt3}}></div>
                    </div>
                    <div className="pdiditem">
                            <img className="pdcill" src={ill4} alt="" srcset="" />
                        <div className="itemdet" dangerouslySetInnerHTML={{__html:dt4}}></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProductDetCard;