import React from "react";
import Normalnav from "./Normalnav";
import "../style/Services.css";

function Services() {
    return(
        <section>
            <Normalnav/>
            <div className="services-intro">
                <h1>Szolgatások</h1>
            </div>
            
        </section>
    );
}

export default Services;