import React, {useEffect} from "react";
import BlogPostDataWidget from "./BlogPostDataWidget";
import BlogPostTags from "./BlogPostTags";
import BTNBlog from "./BTNBlog";
import { Link } from "react-router-dom";
import db from "../apis/firebase-blog-config.js";
import firebase from 'firebase/compat/app';

function BlogPostWidget({ title, intro, coverimg, publishDate, slug, id, tags, seen, comments }) {
    
    
    const doInc = () => {
        const increment = firebase.firestore.FieldValue.increment(1)
        const userPosts = db.collection("posts").doc(id);
        userPosts.update({ seen: increment });
    }

    return(
        <section className="bg-p" key={id}>
            <div className="bg-p-box">
                <div className="bg-p-ci">
                    <Link onClick={doInc} to={`/Blog/${slug}`} >    
                        <img src={coverimg} alt="" />
                    </Link>
                </div>
                <div className="bg-p-de-box">
                    <div className="bg-p-tags-box">
                        <BlogPostTags tags={tags}/>
                    </div>
                    <div className="bg-p-de">
                            <div className="bg-p-title">
                                    <Link onClick={doInc} to={`/Blog/${slug}`} > 
                                        <h4>
                                            {title}
                                        </h4>      
                                    </Link> 
                            </div>
                        <div className="bg-p-intro">
                                <Link onClick={doInc} to={`/Blog/${slug}`} > 
                                    <p>
                                        {intro}
                                    </p>   
                                </Link>        
                        </div>
                    </div>
                    <div className="bg-p-btn-box">
                        <BlogPostDataWidget seen={seen} publishDate={publishDate} comments={comments}/>
                    </div>
                </div>
                <Link onClick={doInc} to={`/Blog/${slug}`} >
                    <BTNBlog/>
                </Link>
                
            </div>
        </section>
    );
}

export default BlogPostWidget;