import React, {useState, useEffect} from "react";
import "../style/ProfileData.css";
import db, {auth} from '../apis/firebase-blog-config';
import {useAuthState} from 'react-firebase-hooks/auth';
import { collection, onSnapshot, query } from "firebase/firestore";
import { toast } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css';
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";

function ProfileData() {

    const [isAdmin, setIsAdmin] = useState(false)

    const [admins, setAdmins] = useState([])
    const [user] = useAuthState(auth);

    const colRef = collection(db, "admins");
    const q = query(colRef);

    const alertSuccess = () => toast.success('Sikeres kijelentkezés!');


    const getAdmin = () => {
        if (admins) {
            for (let index = 0; index < admins.length; index++) {
                if (user.uid == admins[index].uid) {
                    setIsAdmin(true);
                    break;
                }
                
            }
        }
    }

    useEffect(() => {
        getAdmin();
    },[admins])

    useEffect(() => {
        if (user) {
            onSnapshot(q, (snapshot) => {
                let admins = []
                snapshot.docs.forEach((doc) => {
                    admins.push({...doc.data(), id: doc.id})
                })
                setAdmins(admins);
        })
        }
      }, [])


    const logout = () => {
        auth.signOut();
        alertSuccess();
    }

    const [closedFromSvg, setClosedFromSvg] = useState(false)

    const closeWidget = () =>{
        setClosedFromSvg(true)
    }

    return(
        
        <section className="pds w3-animate-bottom" style={{display: closedFromSvg ? "none" : "flex" }}>
            <div className="pdsd">
                <div className="close-wrap">
                    <AiOutlineClose onClick={closeWidget} className="close-btn"/>
                </div>
                <div className="pdsdd1">
                    <div className="pdsddd">
                        <img className="userphoto" src={user ? user.photoURL : null} alt="" />
                        <div className="pdsdddd">
                            <h6>{user ? user.displayName : null}</h6>
                            <p>{user ? user.email : null}</p>
                        </div>
                    </div>
                </div>
                <div className="pdsdd2">
                    <button className="logout-btn" onClick={logout}>Kijelentkezés</button>
                    {isAdmin ? <button className="createpost-btn"><Link to="/Posztkeszites">Posztkészítés</Link></button>  : null}
                </div>
            </div>
        </section>
    );
}

export default ProfileData;