import React, { useEffect } from "react";
import ContactCards from "./components/ContactCards";
import ContactMe from "./components/ContactMe";
import Nav from "./components/Nav";
import Map from "./components/Map";
import Footer from "./components/Footer";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

function Contact() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return(
        <>
            <Nav></Nav>
            <div className="cid">
                <div className="bid">
                        <h1>Vegye fel velünk a kapcsolatot!</h1>
                </div>
                <div className="covernavmore">
                            <Link className="covernavp" to="/" alt="Vissza a kezdőlapra">Kezdőlap</Link><p className="covernavp" ><IoIosArrowForward/></p><p className="covernavp bold" >Kapcsolatfelvétel</p>
                </div>
            </div>
            <ContactCards/>
            <ContactMe/>
            <Map/>
            <Footer/>
        </>
    );
}

export default Contact;