import React, { useRef, useState, useEffect, useParams } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import {ReactComponent as Vector} from "../assets/Vector 1.svg";
import { Link } from "react-router-dom"
import k11 from "../assets/11K.png";
import { useNavigate } from "react-router-dom";
import { collection, onSnapshot, orderBy, where, query } from "firebase/firestore";
import db from "../apis/firebase-blog-config.js";

function About3Sec() {

    const ref = useRef(null);
    const scroll = (scrollOffset) => {
        ref.current.scrollLeft += scrollOffset;
    };

    const colRef = collection(db, "Product");
    
    const [q, setQ] = useState(query(colRef))

    const [product, setProduct] = useState([]);

    useEffect(() => {
        window.scroll(0, 0);
        onSnapshot(q, (snapshot) => {
            let posts = []
            snapshot.docs.forEach((doc) => {
                posts.push({...doc.data(), id: doc.id})
            })
            setProduct(posts);
            setProductsLenght(product.length);
    })
    }, [])

    const [produtslength, setProductsLenght] = useState()

    return(
        <section>
            <div className="a3sd">
                <div className="a3sdd1 reveal">
                    <div>
                        <h1>Szolgáltatásaink</h1>
                        <p>A Szolgáltatásaink segítségével ügyfeleink könnyedén hozzáférhetnek a legkorszerűbb technológiákhoz és szakértelmünkhöz, hogy elérjék céljaikat és növeljék vállalkozásuk hatékonyságát. Szakértő csapatunk rendelkezésre áll, hogy segítsen ügyfeleinknek a szolgáltatásaink kihasználásában.</p>
                        <Link to="/szolgáltatások"><button>Mutasd mindet</button></Link>
                    </div>
                    <div className="a3sdd1d2">
                        <div className="dot active2"></div>
                    </div>
                </div>
                <div className="a3sdd2 revealRight" ref={ref}>
                    {product ? product.map((Card) =>
                    <Link to={`/Termek/${Card.slug}`} key={Card.id}>
                        <div className="a3sdd2d" >
                            <div className="glow-wrap">
                                <i className="glow"></i>
                            </div>
                            <div className="prodimgwrapdiv">
                                <img src={Card.product_img1} alt="" className="product-img" srcset="" />
                            </div>
                            <h2>{Card.name}</h2>
                            <p>{Card.cover_title}</p>
                            <style jsx>
                                {`
                                    .a3sdd2d{
                                        background-image: url(${Card.background_prod})
                                    }
                                `}
                            </style>
                        </div>
                    </Link>
                    ) : "A tartalom nem található"}
                </div>
                <Vector className="vec-ab3s"/>
            </div>
        </section>
    );
}

export default About3Sec;