import React from "react";
import "../style/PrevNextPost.css";
import { BsSignpost2 } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import db from "../apis/firebase-blog-config.js";
import { collection, getDocs } from "firebase/firestore";

function PrevNextPost() {

    const { id } = useParams();

    const [slugPost, setSlugPost] = useState([]);

    const asyncFn = async () => {
        const querySnapshot = await getDocs(collection(db, "posts"));
        setSlugPost(querySnapshot.docs.map(it => it.data()));
    };

    useEffect(() => {
        asyncFn();
    }, [])

    useEffect(() => {
        getNextPost();
        getPrevPost();
    }, [id, slugPost])

    const [nextPost, setNextPost] = useState([])

    const getNextPost = () => {
        for (let index = 0; index < slugPost.length; index++) {
            if (slugPost[index].slug == id && index < slugPost.length) {
                setNextPost(slugPost[index+1])
            }
        }
    }
    const [prevPost, setPrevPost] = useState([])

    const getPrevPost = () => {
        for (let index = 0; index < slugPost.length; index++) {
            if (slugPost[index].slug == id && index > 0) {
                setPrevPost(slugPost[index-1])
            }
        }
    }



    return(
        <section>
            <div className="prenext-cont">
                <div className="line23"></div>
                <div className="prevnext-box">
                    <div className="prev-box">
                        {prevPost !== undefined ? <div><p>Előző bejegyzés</p> <Link to={`/Blog/${prevPost.slug}`}>{prevPost.title}</Link></div> : null}
                    </div>
                    <div className="designelement-box">
                        <BsSignpost2 color="#A2D83E" className="pnpsvg" size="50px"/>
                    </div>
                    <div className="next-box">
                        
                        {nextPost !== undefined ? <div><p>Következő bejegyzés</p><Link to={`/Blog/${nextPost.slug}`}>{nextPost.title}</Link></div> : null}
                    </div>
                </div>
                <div className="line23"></div>
            </div>
        </section>
    );
}

export default PrevNextPost;