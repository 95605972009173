import React, { useState, useEffect } from "react";
import Nav from "./Nav";
import ProductIntro from "./ProductIntro";
import "../style/ProductPage.css";
import ProductDetCard from "./ProductDetCards";
import ProductFAQ from "./ProductFAQ";
import Footer from "../components/Footer.jsx";
import ProductGreetings from "./ProductGreetings";
import ProductDataTable from "./ProductDataTable";
import { useParams, useNavigate } from "react-router-dom";
import { collection, onSnapshot, orderBy, where, query } from "firebase/firestore";
import db from "../apis/firebase-blog-config.js";

function ProductPage() {

    const { id } = useParams();

    const colRef = collection(db, "Product");
    
    const q = query(colRef, where("slug", "==", id));

    const [product, setProduct] = useState([]);

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
      
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 50;
      
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active3");
          } else {
            reveals[i].classList.remove("active3");
          }
        }
      }
      
      window.addEventListener("scroll", reveal);

    useEffect(() => {
        window.scroll(0, 0);
        onSnapshot(q, (snapshot) => {
            let posts = []
            snapshot.docs.forEach((doc) => {
                posts.push({...doc.data(), id: doc.id})
            })
            setProduct(posts);
    })
    }, [])


    return(
        <section className="pps">
            {product ? product.map((det) =>
            <>
                <Nav/>
                <ProductIntro img1={det.product_img1} img2={det.product_img2} price={det.price} name={det.name} title={det.short_det}/>
                <ProductDetCard ill1={det.detcard_ill1} ill2={det.detcard_ill2} ill3={det.detcard_ill3} ill4={det.detcard_ill4} dt1={det.det_card1} dt2={det.det_card2} dt3={det.det_card3} dt4={det.det_card4}/>

                    <div className="dets reveal">
                        <div className="datadiv" dangerouslySetInnerHTML={{__html:det.list1}}></div>
                        <div className="datadiv" dangerouslySetInnerHTML={{__html:det.list2}}></div>
                    </div>

                
                <div className="graydiv"></div>
                <ProductDataTable data_table={det.data_Table} sizes={det.sizes_table}/>
                <ProductGreetings id={det.id}/>
                <ProductFAQ id={det.id}/>
                <Footer/>
            



                <style jsx>{`
                    .pis{
                        padding: 200px 0px;
                        background-image: url(${det.background_prod});
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                        @media screen and (max-width: 550px) {
                            .pis{
                                padding: 100px 0px;
                            }
                        
                `}

                </style>
            </>
        ) : <p>A termék nem található</p>}
        </section>
    );
}

export default ProductPage;