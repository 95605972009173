import React, { useEffect } from "react";
import SearchBar from "./SearchBar";
import TagsWidget from "./TagsWidget";
import PopularBlog from "./PopularBlogs";
import NewsletterWidget from "./NewsletterWidget";
import "../style/slug.css";
import Footer from "./Footer";
import Nav from "./Nav";
import BlogPostData from "./BlogPostData";
import MadeByBlog from "./MadeByBlog";
import PrevNextPost from "./PrevNextPost";
import Comments from "./Comments";
import { useParams, useNavigate, Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useState } from "react";
import db from "../apis/firebase-blog-config.js";
import { collection, onSnapshot, orderBy, where, query } from "firebase/firestore";


function Slug() {

  const { id } = useParams();

  const navigate = useNavigate();

    const [slugPost, setSlugPost] = useState([]);
    
    const colRef = collection(db, "posts");

    const q = query(colRef, where("slug", "==", id));

    

    const [comments, setComments] = useState([]);

    const getComments = () => {
      if (slugPost) {
        const colRef2 = collection(db, `posts/${slugPost.id}/Comments`);
        const q2 = query(colRef2, orderBy("date", "desc"));
        onSnapshot(q2, (snapshot) => {
          let comment = []
          snapshot.docs.forEach((doc) => {
              comment.push({...doc.data(), id: doc.id})
          })
          setComments(comment);
        })
      }
    }

    useEffect(() => {
      window.scrollTo(0, 0)
      onSnapshot(q, (snapshot) => {
        let posts = []
        snapshot.docs.forEach((doc) => {
            posts.push({...doc.data(), id: doc.id})
        })
        setSlugPost(posts[0]);
      })
      
    }, [id])


    useEffect(() => {
      getComments();
    }, [slugPost])

    


  return (
    <section>
      <Nav></Nav>
      <Link to="/Blog" className="navtohome"><IoIosArrowBack/>Vissza a blogra</Link>
      <div>
        <img className="GEBPI" src={slugPost.coverimg} alt="" />
      </div>
      <section className="slug-ss">
        <div className="slug-ssd2">
          <div className="Blogpost-title">{slugPost.title}</div>
          <div className="lineslug"></div>
          <BlogPostData seen={slugPost.seen} publishDate={slugPost.date_Published} comments={comments.length}/>
          <div className="Blogpost-content" dangerouslySetInnerHTML={{__html:slugPost.Content}}>
          </div>
          <MadeByBlog author={slugPost.author} job={slugPost.job}/>
          <PrevNextPost/>
          <Comments Comments={comments} id={slugPost.id}/>
        </div>
        <div className="Blogwidget-div">
          <SearchBar onSubmit={()=>navigate("/Blog")}/>
          <TagsWidget/>
          <PopularBlog/>
          <NewsletterWidget/>
        </div>
        
      </section>
      <Footer/>
    </section>
  );
}

export default Slug;