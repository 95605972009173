import React from "react";
import MobileNav from "./MobileNav";
import Normalnav from "./Normalnav";

function Nav() {

    return(
        <>
            <MobileNav></MobileNav>
            <Normalnav></Normalnav>
        </>
    )
}

export default Nav;