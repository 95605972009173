import React from "react";

function SearchFor({ query }) {

    return(
        <section className="cat-section">
            <div className="cat-div">
                <p>Keresés:</p>
                <h5>{query}</h5>
            </div>
        </section>
    )
}

export default SearchFor;