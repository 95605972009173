import React, { useEffect } from "react";
import Nav from "./components/Nav";
import Intro from "./components/Intro";
import "./style/Home.css";
import Counterbg from "./assets/counter-bg.png";
import HGEB from "./components/HGEB";
import Footer from "./components/Footer";
import NewestBlogPosts from "./components/NewestBlogPostIntroHome";
import About2Sec from "./components/about2sec";


function Home() {

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
      
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 50;
      
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active3");
          } else {
            reveals[i].classList.remove("active3");
          }
        }
      }
      
      window.addEventListener("scroll", reveal);
    return(
        <>
        
        <Nav className="w3-animate-top"></Nav>
            <div className="landing-div">
                <Intro/>
            </div>
            <About2Sec/>
            <img className="cbg" src={Counterbg} alt="" />
            <HGEB/>
            <NewestBlogPosts/>
            <Footer/>
        </>
    );
}

export default Home;