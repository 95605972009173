import React, { useEffect, useState } from "react";
import { BsNewspaper } from "react-icons/bs";
import "../style/NewsletterWidget.css";
import db from "../apis/firebase-blog-config";
import firebase from 'firebase/compat/app';
import { toast } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css';
import { collection, onSnapshot, query, where } from "firebase/firestore";


function NewsletterWidget() {

    const [sent, setSent] = useState(false);
    const [input, setInput] = useState("");
    const [once, setOnce] = useState(0);
    const inputHandler = (e) => {
      setInput(e.target.value);
    };

    const [emails, setEmails] = useState();
    const submitHandler = (e) => {
        e.preventDefault();
        const colRef2 = collection(db, "newsletter-emails")
        const q2 = query(colRef2 , where("email", "==", input)); 
        
        onSnapshot(q2, (snapshot) => {
            let emails2 = []
            snapshot.docs.forEach((doc) => {
                emails2.push({...doc.data(), id: doc.id})
            })
            setEmails(emails2);
            setOnce(prev => prev + 1);
        })
        
    };

      useEffect(() => {
        if (emails) {
            if (emails.length !== 0 && sent == false) {
                const alertWarning = () => toast.warning('Az email már regisztrálva van.');
                alertWarning();
            } else {
                if (input !== "") {
                    db.collection("newsletter-emails").add({
                        email: input,
                        time: firebase.firestore.FieldValue.serverTimestamp(),
                      });
                      setInput("");
                      const alertSuccess = () => toast.success('Sikeresen feliratkoztál a hírlevelünkre!');
                      alertSuccess();
                      setSent(true)
                      console.log("ok")
                }  
            }   
        }
      }, [once])

    return(
        <section className="NLWidget-cont">
            <div className="NLWidget-box">
                <div className="icon-box">
                    <BsNewspaper color="#01233F" size="90px" ></BsNewspaper>
                </div>
                {sent == false ? 
                <>
                    <div>
                    <h5 className="NLtitle">Iratkozz fel, hogy értesitést kapj új tartalmainkról!</h5>
                    </div>
                    <div>
                        <form onSubmit={submitHandler} action="">
                            <input 
                            className="NLinput" 
                            type="email" 
                            name='reply_to'
                            placeholder='Email cím...'
                            value={input}
                            onChange={inputHandler}
                            required
                            />
                            <button type="submit" className="NLbtn">OK</button>
                        </form>
                    </div>
                </>
                :   
                <>
                    <div>
                        <h5 className="succesh1">Sikeresen feliratkoztál a hírlevelünkre!</h5>
                    </div>
                </>
                   }
            </div>
        </section>
    );
}

export default NewsletterWidget;