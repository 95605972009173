import { EpsBankElement } from "@stripe/react-stripe-js";
import React, { useRef } from "react";
import im1 from "../assets/OIP.jfif";

function About4Sec() {

    const ref = useRef(null);
    const scroll = (scrollOffset) => {
        ref.current.scrollLeft += scrollOffset;
    };

    const elmployees = [
        {
            name: "John Doe",
            job: "Programmer",
            profile: im1,
            subject: "Lorem ipsum dolor sit amet",
            id: 1,
        },
        {
            name: "John Doe",
            job: "Programmer",
            profile: im1,
            subject: "Lorem ipsum dolor sit amet",
            id: 2,
        },
        {
            name: "John Doe",
            job: "Programmer",
            profile: im1,
            subject: "Lorem ipsum dolor sit amet",
            id: 3,
        },
        {
            name: "John Doe",
            job: "Programmer",
            profile: im1,
            subject: "Lorem ipsum dolor sit amet",
            id: 4,
        },
        {
            name: "John Doe",
            job: "Programmer",
            profile: im1,
            subject: "Lorem ipsum dolor sit amet",
            id: 5,
        },
    ];

    return(
        <section>
            <div className="a4sd reveal">
                <h1>Munkatársaink</h1>
                <div className="row">
                    {elmployees ? elmployees.splice(0,3).map((employee) =>
                        <div className="col-4" key={employee.id}>
                            <div className="card">
                                <div className="front">
                                <img className="profile-pic" src={employee.profile} alt="" />
                                <span className="fa fa-user px-3">
                                    <h3>{employee.name}</h3>
                                    <h6>{employee.job}</h6>
                                </span>
                                </div>
                                <div className="back">
                                    <p>"{employee.subject}"</p>
                                </div>
                            </div>
                        </div>
                    ) : "Az alkalmazottak nem találhatóak"}
                </div>
                <div className="row">
                    {elmployees ? elmployees.splice(0,5).map((employee) =>
                        <div className="col-6" key={employee.id}>
                            <div className="card">
                                <div className="front">
                                <img className="profile-pic" src={employee.profile} alt="" />
                                <span className="fa fa-user  px-3">
                                    <h3>{employee.name}</h3>
                                    <h6>{employee.job}</h6>
                                </span>
                                </div>
                                <div className="back">
                                    <p>"{employee.subject}"</p>
                                </div>
                            </div>
                        </div>
                    ) : "Az alkalmazottak nem találhatóak"}
                </div>
            </div>
        </section>
    );
}

export default About4Sec;