import React from "react";
import { HiOutlineArrowLongRight } from "react-icons/hi2";

function AboutSection() {
    return(
        <section className="a1s w3-center" id="about">
            <div className="a1sd">
                <div className="a1sdd1">
                    <img className="a1sdd1i" src="https://images.unsplash.com/photo-1665037935112-ff9a01c93f9b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80" alt="" />
                </div>
                <div className="a1sdd2"> 
                    <div className="a1sdd1d"></div>
                    <div className="a1sdd2d">
                        <h1>LOREM IPSUM</h1>
                        <h3>LOREM IPSUM DOLOR SIT AMET</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        <button className="a1sdd2dbtn">Lorem<HiOutlineArrowLongRight size='20px' style={{ "marginLeft" : "10px" }}/></button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutSection;