import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { FaQuoteRight } from "react-icons/fa";
import img from "../assets/prof.jpg";
import imgs from "../assets/R.png";

function AboutGreetings() {

    const greetings = [
        {
            id: "1",
            name: "John Doe",
            job: "Lorem ipsum dolor",
            quote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
            profile: img
        },
        {
            id: "2",
            name: "John Doe",
            job: "Lorem ipsum dolor",
            quote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
            profile: img
        },
        {
            id: "3",
            name: "John Doe2",
            job: "Lorem ipsum dolor",
            quote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
            profile: img
        },
        {
            id: "4",
            name: "John Doe2",
            job: "Lorem ipsum dolor",
            quote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
            profile: img
        },
        {
            id: "5",
            name: "John Doe3",
            job: "Lorem ipsum dolor",
            quote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
            profile: img
        },
        {
            id: "6",
            name: "John Doe3",
            job: "Lorem ipsum dolor",
            quote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
            profile: img
        }
    ];

    const companys = [
        {
            image: imgs,
            id: 1
        },
        {
            image: imgs,
            id: 2
        },
        {
            image: imgs,
            id: 3
        },
        {
            image: imgs,
            id: 4
        },
        {
            image: imgs,
            id: 5
        },
        {
            image: imgs,
            id: 6
        },
    ];
    const [actual, setActual] = useState(0);
    const [toActual, setToActual] = useState(2);
    const [toBack, setToBack] = useState(false);
    const [toPrev, setToPrev] = useState(true);
    const [shake, setShake] = useState(false);
    
    useEffect(() =>{
        if (actual == 0) {
            setToBack(false);
        }
        if (toActual == 6) {   
            setToPrev(false);
        }
    }, [actual])

    const Back = () =>{
        if (actual >= 2) {
            setShake(true);
        setTimeout(() => {
            setShake(false)
            setActual(prev => prev - 2);
            setToActual(prev => prev - 2);
            setToPrev(true)
        }, 400);
        }
        
    }

    const ref = useRef(null);
    const scroll = (scrollOffset) => {
        ref.current.scrollLeft += scrollOffset;
    };

    const Prev = () =>{
        if (toActual < 6) {
            setShake(true);
            setTimeout(() => {
                setShake(false)
                setActual(prev => prev + 2);
                setToActual(prev => prev + 2);
                setToBack(true)
            }, 400);  
        } 
    }    

    return(
        <section className="ags1 w3-center reveal" id="about">
            <div className="agdstepperbox">
                <h1>Ügyfeleink véleményei</h1>
                <div className="agdstepper">
                    <IoIosArrowBack onClick={Back} size="30px" color={toBack ? "black" : "gray"} cursor="pointer"/>
                    <IoIosArrowForward onClick={Prev} size="30px" color={toPrev ? "black" : "gray"} cursor="pointer"/>
                </div>
            </div>
            <div className = {shake ? `shake gfd` : `gfd`}>
                {greetings.slice(actual, toActual).map((element) =>
                    <div className="gd" key={element.id}>
                        <div className="gdd">
                            <div>
                                <img src={element.profile} className="elementimg"/>
                                <FaQuoteRight className="elementdet3" color="#A2D83E" size="30px"/>
                                <div className="elementdet">
                                    <h3 className="elementname">{element.name}</h3>
                                    <h3 className="elementjob">/ {element.job}</h3>
                                </div>
                                <div className="elementdet2">
                                    <p className="elementquote">{element.quote}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                )}
            </div>
            <div className="refimgcont">
                {companys.map((element) => 
                    <div key={element.id}>
                        <img src={element.image} className="referenceimg img-fluid"/>
                    </div>
                )}
            </div>
        </section>
    );
}
export default AboutGreetings;