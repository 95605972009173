import Hamburger from "./Hamburger";
import { useState } from 'react';
import "../style/Mobilenav.css"
import Logo from "../logo-removebg-preview.png";
import { NavLink, Link } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import {auth} from '../apis/firebase-blog-config';
import {useAuthState} from 'react-firebase-hooks/auth';
import Login from "./Login";
import ProfileData from "./ProfileData";

function MobileNav() {

    const [colorChange, setColorchange] = useState(false);
        const changeNavbarColor = () =>{
           if(window.scrollY >= 1){
             setColorchange(true);
           }
           else{
             setColorchange(false);
           }
        };
        window.addEventListener('scroll', changeNavbarColor);

    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    const toggleHamburger = () =>{
        setHamburgerOpen(current => !current)
    }

    const [user] = useAuthState(auth);

    const [openProfile, setOpenProfile] = useState(false);

        const handleClick = () =>{
            if (user) {
                setOpenProfile(current => !current);
            }
        }

    return(
        <div className="mobilenav">
            <div className='navigation'>
                <ul className="w3-animate-left">
                                    <NavLink 
                                    to="/" 
                                    activeclassname="active"
                                    className="mobnava"
                                    end
                                    >
                                        Kezdőlap
                                    </NavLink>
                                    <NavLink
                                    activeclassname="active"
                                    className="mobnava"
                                    to="/Blog">
                                        Blog
                                    </NavLink>
                                    {/*<NavLink 
                                    activeClassName="navbar__link--active"
                                    to="/szolgáltatások">
                                        Szolgáltatásaink
                                    </NavLink>*/}
                                    <NavLink 
                                    activeclassname="active"
                                    className="mobnava"
                                    to="/Rolunk">
                                        Cégünk
                                    </NavLink>
                                    <NavLink 
                                    activeclassname="active"
                                    className="mobnava"
                                    to="/Kapcsolatfelvetel">
                                        Kapcsolatfelvétel
                                    </NavLink>
                                {user ? 
                                            <CgProfile 
                                                style={{"cursor": "pointer"}} 
                                                onClick={handleClick}
                                                size="22px"
                                            />
                                :
                                    <Login/>
                                }
                </ul>
                    {openProfile && user ?  
                                        <ProfileData 
                                            id="togglePD"
                                        /> 
                            : null}
                    <div className="hamburger" onClick={toggleHamburger}>
                        <Hamburger isOpen={hamburgerOpen}/>
                    </div>
                    <div className="moblogo">
                        <Link to="/"><img src={Logo} height="40px" alt="logo" /></Link>
                    </div>
            </div>


            <style jsx>{`
                .navigation{
                    width: 100%;
                    height: 50px;
                    background-color: white;
                    position: fixed;
                    transition: 0.5s ease-in-out;
                    z-index: 500;
                    box-shadow: 0 2px 4px rgb(146 161 176 / 15%);
                }
                
                
                .navigation ul{
                    display:flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    float: right;
                    margin: 0px;
                    padding: 20px;
                    overflow: hidden;
                }
                .navigation ul li{
                    list-style-type: none;
                    padding-right: 10px;
                    color: black;
                }
                .hamburger{
                    z-index: 6;
                } 
                @media (max-width: 767px){
                  
                    .hamburger{
                        position: absolute;
                        padding-top: 10px;
                        margin-top: 3px;
                        margin-left: 10px;
                        z-index: 6;
                    }
                
                   
                    .navigation ul{
                        display: ${hamburgerOpen ? 'flex' : 'none'};
                        background-color: white;
                        z-index: 20;
                        height: 100vh;
                        width: 50vw;
                        margin-top: 50px;
                        position: fixed;
                        
                    }
                }
                
               
                
            `}</style>
        </div>
    )
}

export default MobileNav;