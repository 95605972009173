import React from "react";
import "../style/BlogPostWidget.css";
import { GrView } from "react-icons/gr";
import { BiComment } from "react-icons/bi";
import { BsCalendarDate } from "react-icons/bs";

function BlogPostData({ publishDate, seen, comments }) {
    return(
        <div className="bg-p-data">
            <div className="bg-p-dd">
                <GrView className="icon" size="14px"/>
                <p className="icon">{seen}</p>
                <p>Megtekintés</p>
            </div>
            <div className="vertical-lines"></div>
            <div className="bg-p-dd">  
                <BiComment className="icon" size="14px"/>
                <p className="icon">{comments}</p>
                <p>Hozzászólás</p>
            </div>
            <div className="vertical-lines"></div>
            <div className="bg-p-dd">
                <BsCalendarDate className="icon" size="14px"/>
                <p className="icon">{publishDate}</p>
            </div>
        </div>
    );
}

export default BlogPostData;