import React from "react";
import "../style/BlogPostWidget.css"

function BlogPostTags({ tags }) {
    
    return(
        <div className="bg-p-tags">
            {tags ? tags.map((tag) => 
                <button className="tag-box" key={tag}>
                    {tag}
                </button>
            ) : "Nem találhatóak a címkék"}
        </div>
    );
}

export default BlogPostTags;