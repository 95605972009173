import React, { useState, useEffect } from "react";
import { collection, getDocs, onSnapshot, orderBy, where, query, limitToFirst, addDoc } from "firebase/firestore";
import db, {auth} from '../apis/firebase-blog-config';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "../style/CreatePost.css";
import Nav from "../components/Nav";
import { ToastContainer, toast } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css';
import { AiOutlineInfoCircle } from 'react-icons/ai';

function CreatePost() {

    const auth = getAuth();
    
    const [admin, setAdmin] = useState()

    onAuthStateChanged(auth, (user) => {
        if (user && (user.uid === "9NQsAO1yg8RChRv8hvR6qp0QDfx2" || user.uid === "kf3LxPVnoBcKitZ7bMEuA182kLh1")) {
            console.log("ok")
                
        } else {
            navigate("/")
        }
    });



    const [title, setTitle] = useState("");
    const [introText, setIntroText] = useState("");
    const [postText, setPostText] = useState("");
    const [coverPhoto, setCoverPhoto] = useState("");
    const [job, setJob] = useState("");
    const [date, setDate] = useState("");
    const [author, setAuthor] = useState ("");
    const [seen, setSeen] = useState(0);
    const tags2 = ["Interjú", "Cég", "Zöld", "Energetika", "Megújulás", "Föld"];
    const [title_query, setTitleQuery] = useState([])
    const postsCollectionRef = collection(db, "posts");
    let navigate = useNavigate();

    const alertSuccess = () => toast.success('Sikeres feltöltés!');
    const alertWarning = () => toast.warning('Sikertelen feltöltés!');

    const [tags, setPostTags] = useState([]);

    const handleCheckboxChange = event => {
        
            if (tags.includes(event.target.id)) {
                const newArray = tags.filter(element => {
                    return element !== event.target.id;
                })
                setPostTags(newArray);
            } else {
                tags.push(event.target.id);
            }
      };

      const titleFunc = (event) => {
        setTitleQuery(title.split(" "));
        setTitle(event.target.value);
      }

    const createPost = async () => {
            try {
                await addDoc(postsCollectionRef, {
                    title,
                    introText,
                    postText,
                    coverPhoto,
                    author,
                    job,
                    date,
                    seen,
                    tags,
                    title_query
                })
                setTitle('');
                setIntroText('');
                setPostText('');
                setJob('');
                setDate('');
                setAuthor('')
                alertSuccess();
            } catch (error) {
                alertWarning();
            }
            
    };
    
    

    return (
        <section className="cp">
            <Nav/>
                <section className="cps">
                        <div className="cpsd">        
                        <h1>Tartalom készítő</h1>
                            <div className="cpsdd">
                                <div className="inputGp">
                                    <div className="title-box-cp">
                                        <label>Cím</label>
                                        <a data-toggle="tooltip" title="A cím végére rakjon SPACE-t"><AiOutlineInfoCircle size="15px" color="white"/></a>
                                    </div>
                                    <input
                                        placeholder="Cím..."
                                        onChange={titleFunc}
                                        required
                                    />
                                </div>
                                <div className="inputGp">
                                    <label>Bevezető kép</label>
                                    <input
                                        type="text"
                                        placeholder="Bevezető kép linkje..."
                                        onChange={(event) => {
                                        setCoverPhoto(event.target.value)
                                        }}
                                        required
                                    />
                                </div>
                                <div className="inputGp">
                                    <label>Bevezető szöveg</label>
                                    <textarea
                                        placeholder="Bevezető szöveg..."
                                        onChange={(event) => {
                                        setIntroText(event.target.value);
                                        }}
                                        required
                                    />
                                </div>
                                <div className="inputGp">
                                    <label>Tartalom</label>
                                    <textarea
                                        placeholder="Tartalom..."
                                        onChange={(event) => {
                                        setPostText(event.target.value);
                                        }}
                                        required
                                    />
                                </div>
                                <div className="inputGp">
                                    <label>Szerző</label>
                                    <input
                                        placeholder="Szerző..."
                                        onChange={(event) => {
                                        setAuthor(event.target.value);
                                        }}
                                        required />
                                </div>
                                <div className="inputGp">
                                    <label>Munkakör</label>
                                    <input
                                        placeholder="Munkakör..."
                                        onChange={(event) => {
                                        setJob(event.target.value);
                                        }}
                                        required />
                                </div>
                                <div className="inputGp">
                                    <label>Címkék</label>
                                        <div className="tagsgp">
                                            {tags2.map(tag =>
                                                <label key={tag}>
                                                    <input 
                                                    type="checkbox" 
                                                    name={tag} 
                                                    id={tag}
                                                    onChange={handleCheckboxChange}
                                                    />
                                                        {tag}
                                                </label>
                                            )}
                                        </div>
                                </div>
                                <div className="inputGp">
                                    <label>Dátum</label>
                                    <input
                                        type="date"
                                        placeholder="Dátum..."
                                        required
                                        onChange={(event) => {
                                        setDate(event.target.value);
                                        }}
                                    />
                                </div>
                                <div className="cpbtnd">
                                    <button onClick={createPost} className="btn-outline cpbtn">Feltöltés</button>
                                </div>
                            </div>
                        </div>
                </section>      
        </section>       
    );
}

export default CreatePost;