import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import db from "../apis/firebase-blog-config.js";
import firebase from 'firebase/compat/app';

function Bookwidget({ title, image, intro, id, id2}) {

    const doInc = () => {
        const increment = firebase.firestore.FieldValue.increment(1)
        const userPosts = db.collection("GEB").doc(id2);
        userPosts.update({ seen: increment });
    }
    
    return(
        <section>
            <div className="bookwidget-box">
                <div>
                    <img className="coverimg-book" src={image} alt="" />
                    <div className="watch-btn-div"><Link className="bwlink" to={`/GEB/${id}`} onClick={doInc}><button className="watch-btn">Megnézem<BsArrowRight size="10px" className="arrowright"/></button></Link></div>
                </div>
                <div className="bookwidget-title">
                    <h3>{title}</h3>
                    <p>{intro}</p>
                </div>
            </div>
        </section>
    );
}

export default Bookwidget;